








import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import FormBase from "@/shared/classes/form/form-base";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import { getLanguagesByVersion, getNodeApiUrlByVersion } from "@/config";
import FormField from "@/shared/components/form/FormField.vue";
import RiskTemplateForm from "@/admin/components/risk-templates/RiskTemplateForm.vue";
import ArrayField from "@/shared/classes/form/fields/array-field";
import localeHelper from "@/shared/helpers/locale.helper";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import { CompanyRoutes } from "@/shared/router/company";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import { getVersion } from "@/shared/helpers/version.helper";

@Component({
  components: { PageHeader, RiskTemplateForm, FormField },
  methods: { __ }
})
export default class RiskAssessmentsCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`${getNodeApiUrlByVersion()}/company-hse-risk-templates`)
    .setTranslatable(true)
    .setCurrentLanguage(getLanguagesByVersion([localeHelper.getLocale()])[0])
    .setFields([
      new SearchableField()
        .setKey("hseRiskCategoryUuid")
        .setEntryKey("hseRiskCategoryUuid")
        .isRequired()
        .setTitle(__("admin.components.risk-templates.form.category"))
        .loadItems({
          endpoint: `${getNodeApiUrlByVersion()}/hse-risk-categories`,
          value: "uuid",
          title: "name",
          perPage: 20
        }),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey(`name.${localeHelper.getLocale()}`)
        .setTitle(__("admin.components.risk-templates.form.risk-title"))
        .setSize(FieldSizes.half)
        .setRequired(localeHelper.getLocale() === getVersion()),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey("name")
        .setTranslatable(true)
        .setTitle(__("admin.components.risk-templates.form.risk-title"))
        .setSize(FieldSizes.half)
        .setRequired(localeHelper.getLocale() !== getVersion()),
      new ArrayField()
        .setKey("barriers")
        .setDense(true)
        .setDeleteButtonProps({ color: "transparent", elevation: 0, class: "mt-5" })
        .setAddButtonProps({ isIcon: true, class: "justify-end mr-12" })
        .setJustifyChildren("justify-space-between")
        .setChildren([
          new Field()
            .setType(FieldTypes.textArea)
            .setKey(`title.${localeHelper.getLocale()}`)
            .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
            .setClasses("ml-4")
            .setSize(FieldSizes.half)
            .setRequired(localeHelper.getLocale() === getVersion()),
          new Field()
            .setType(FieldTypes.textArea)
            .setKey("title")
            .setTranslatable(true)
            .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
            .setClasses("ml-4")
            .setSize(FieldSizes.half)
            .setRequired(localeHelper.getLocale() !== getVersion()),
        ]),
    ])
    .setValidate(true, { onlyRegionLangRequired: true })
    .setShowDefaultValidationError(true)
    .setSubmit({
      text: __("general.create")
    })
    .setInjectValues({
      isVisible: true,
      companyUuid: this.company.uuid
    })
    .setChangeDataBeforeSubmit(data => {
      return {
        ...data,
        barriers: data.barriers.map((el: any) => {
          return Object.keys(el).reduce((s, c) => {
            if (c.includes(".")) return s;
            return { ...s, [c]: el[c] };
          }, {});
        })
      };
    })
    .setOnSuccess(this.onSuccess);

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.riskAssessmentsIndexTabTemplates });
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __("company.views.risk-assessments.create.form.on-success"),
    });

    this.goBack();
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
